


	

.gallery {
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 300vh;
  /* position: fixed; */
  top: 0;
  
  left: 49%;
  /* transform: translateX(-50%); */
  overflow: hidden;
  
}
@media (max-width: 768px) {
  .gallery {
    width: 100%;
  }
  .cols {
    margin: 0;
  }
  .image {
    padding: 0 !important;
  }
  .cols img {
    box-shadow: 0px 1px 6px #8a8a8a;
  }
  .btn-lft-align{
    text-align: center;
  }
}

@media (min-width: 769px) {
 
  .cols img {
    box-shadow: 1px 1px 15px #8a8a8a;
  }
  .btn-lft-align{
    text-align: right;
  }
}

.cols {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-self: flex-start;
  justify-self: flex-start;
  margin:5px;
}

.cols:nth-child(2) {
  align-self: flex-end;
  justify-self: flex-end;
}
.short-desc {
  overflow: hidden;
  /* max-height: 180px; Adjust as needed */
  transition: max-height 0.5s ease;
}
.cols:nth-child(4) {
  align-self: flex-end;
  justify-self: flex-end;
}

.cols .image {
  width: 100%;
  
  padding: 1rem;
}

.cols .image:hover {
  z-index: 99999999999 !important;
  filter: saturate(1);
}

.cols img {
  transition: 0.3s ease-out;
  overflow: hidden;
 
  width: 100%;
  border-radius:20px;
}
.bg-overlay-slider-bottom {
  
  opacity: 1;
  height: 400px;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  content: "";
  z-index: 9;
}


.hide{
  display:none;
  
}
