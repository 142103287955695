.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btns1, .buttons {
  display: inline-block;
  border: 1px solid #3BB77E;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 30px;
  border-radius: 4px;
  color: #333;
  border: 1px solid #3BB77E;
  background-color: #fff;
  cursor: pointer;
  transition: all 300ms linear 0s;
  letter-spacing: 0.5px;
}
.check {
  width: 10%;
  position: absolute;
  right: 40%;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}







.contact-info {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
}
.contact-info b {
  font-weight: bold;
}
.contact-info .data {
  text-align: left;
}


.tags-list {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
  margin: 0;
  display: flex; /* Align items horizontally */
}

.tags-list li {
  padding: 10px 20px; /* Adjust padding as needed */
  position: relative; /* Position relative for proper placement of the border */
}

.tags-list li:not(:last-child)::after {
  content: ''; /* Empty content for the pseudo-element */
  position: absolute; /* Position absolute for precise placement */
  right: 0; /* Align to the right edge */
  top: 10%; /* Center vertically */
  width: 1px; /* Thickness of the vertical line */
  height: 80%; /* Height of the vertical line */
  background-color: #ccc; /* Color of the vertical line */
}
.scrollable-table-container {
    max-height: 400px; /* Set the height as needed */
    overflow-y: auto;  /* Enable vertical scrolling and show scrollbar */
    border: 1px solid #ddd; /* Optional: adds a border to the container */
    scrollbar-width: 0px!important;
    
}


.scrollable-table-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-table-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 768px) {
  .table-responsive {
      display: block;
  }

  .table thead {
      display: none;
  }

  .table tr {
      display: block;
      margin-bottom: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
  }

  .table td {
      display: block;
      
      border-bottom: 1px solid #ddd;
  }

  .table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      
      font-weight: bold;
      white-space: nowrap;
  }
  .cart_total_label, .cart_total_amount {
    display: block;
    width: 100%;
}

.cart_total_label {
    text-align: left;
}

.cart_total_amount {
    text-align: right;
    margin-top:-30px !important;
}
}



